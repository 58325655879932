import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { render } from '../render';

import { getPage } from '../../redux/pageModal/pageModal.action';
import { pageGetData, pageGetLoading } from '../../redux/pageModal/pageModal.selector';

import style from './ModalPageContent.module.scss';

const ModalPageContent = props => {
    const { pagePath, googleMapKey } = props;
    const state = useSelector(state => state);
    const pageData = pageGetData(state);
    const pageLoading = pageGetLoading(state);
    const dispatch = useDispatch();
    const pageTemp = useRef(null);

    // const [pageCache, setPageCache] = useState(null);

    const pageCache = useMemo(() => {
        if (pageData.contents && JSON.stringify(pageData) !== JSON.stringify(pageCache)) {
            const breadcrumb = pageData.breadcrumb;
            if (
                pageData.contents.length > 0 &&
                breadcrumb?.display &&
                pageData.contents.findIndex(i => i.type === 'breadcrumb') < 0
            ) {
                pageData.contents.splice(0, 0, {
                    ...breadcrumb,
                    type: 'breadcrumb',
                    // isInner: true,
                });
            }
            pageTemp.current = pageData;
            return pageData;
        } else {
            return pageTemp.current;
        }
    }, [pageData]);

    useEffect(() => {
        if (pagePath && !pageLoading) {
            // console.log('modal page load');
            if (pagePath) {
                dispatch(getPage(pagePath));
            }
        }
    }, [pagePath]);
    return (
        <div className={style.el}>
            <div className={style.container}>
                {pageCache?.contents &&
                    pageCache.contents.map((content, i) =>
                        render(content, i, pageCache.contents, false, false, googleMapKey)
                    )}
            </div>
        </div>
    );
};

ModalPageContent.propTypes = {
    pagePath: PropTypes.string,
    googleMapKey: PropTypes.string,
};

export default ModalPageContent;
