import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import { useRouter } from 'next/router';

import { setBodyNoScroll } from '../../utils/common';
import FilterSelect from '../FilterSelect';
import CharityList from './CharityList';
import CustomSelect from '../CustomSelect';
import { prepareGlobalFilterClasses } from '../../styles/globalFilterClasses';

import { getFilters, getCharity, getCharityLoading } from '../../redux/charity/charity.selector';
import { addFilter, removeFilter, setFilters, clearFilters, fetchCharity } from '../../redux/charity/charity.action';
import InfiniteScroll from 'react-infinite-scroll-component';
import LottieAnimation from '../LottieAnimation';
import { loadingLottieJSON } from '../../utils/loadingLottieJSON';

import style from './CharityList.module.scss';
import {
    convertFiltersToObject,
    getFiltersFromQueryString,
    getQueryStringValue,
    removeQueryStringValue,
    setQueryStringValue,
} from '../../utils/queryString';

const CharityListing = ({ title, filters, charities, copies }) => {
    const filtersRef = useRef(null);
    const elRef = useRef(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [sort, setSort] = useState('a_z');
    const [page, setPage] = useState(0);
    const state = useSelector(state => state);
    const selectedFilters = getFilters(state);
    // const router = useRouter();
    const searchFilter = selectedFilters.find(filter => filter.single) || { value: '' };

    const charityFromApi = getCharity(state);
    const charityLoading = getCharityLoading(state);

    const dispatch = useDispatch();
    const handleSearchChanged = e => {
        const type = e.target.name;
        const value = e.target.value;
        const label = value;
        const obj = { type: type, value: value, label: label, single: true };
        if (value === '') {
            dispatch(clearFilters(type));
        } else {
            dispatch(addFilter(obj));
        }
    };
    const handleFilterSelect = e => {
        const type = e.currentTarget.name;
        const value = e.currentTarget.value;
        const label = e.currentTarget.getAttribute('data-label');
        const obj = { type: type, value: value, label: label };
        if (value === 'all') {
            dispatch(clearFilters(type));
        } else {
            if (selectedFilters.length < 8) dispatch(addFilter(obj));
            else dispatch(removeFilter(obj));
        }
    };

    const [charityCache, setCharityCache] = useState(charities);
    const [filterCache, setFilterCache] = useState(selectedFilters);

    const sortSelect = [
        {
            label: copies.sortLatestLabel,
            value: 'latest',
        },
        {
            label: copies.sortAZLabel,
            value: 'a_z',
        },
        {
            label: copies.sortZALabel,
            value: 'z_a',
        },
    ];

    const removeThisFilter = e => {
        const type = e.currentTarget.getAttribute('data-type');
        const value = e.currentTarget.getAttribute('data-value');
        const label = e.currentTarget.getAttribute('data-label');
        const obj = { type: type, value: value, label: label };
        dispatch(addFilter(obj));
    };

    const clearFiltersClick = () => {
        dispatch(clearFilters(''));
    };

    const fetchData = () => {
        setPage(page + 1);
    };

    const filterClick = () => {
        setFilterOpen(true);
    };

    const convertFiltersToString = () => {
        return JSON.stringify(convertFiltersToObject(selectedFilters));
    };

    useEffect(() => {
        if (JSON.stringify(filterCache) !== JSON.stringify(selectedFilters)) {
            setFilterCache(selectedFilters);
        }
    }, [selectedFilters]);

    const isFirstRun = useRef(true);
    const router = useRouter();

    useEffect(() => {
        const sortValue = getQueryStringValue('sort', '', router);
        if (sortValue && sort !== sortValue) {
            setSort(sortValue);
        }
    }, []);

    useEffect(() => {
        isFirstRun.current = true;
        isFirstSort.current = true;
        return () => {
            setCharityCache(charities);
            dispatch(clearFilters(''));
        };
    }, [router.pathname]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            const filters = getFiltersFromQueryString('filters');
            dispatch(setFilters(filters));
            return;
        }
        setQueryStringValue('filters', convertFiltersToObject(selectedFilters || []), '', router);
        dispatch(fetchCharity(0, convertFiltersToString(), sort));
    }, [filterCache]);

    useEffect(() => {
        const handleRouteChange = url => {
            const queryString = url.split('?').pop();
            const filters = getFiltersFromQueryString('filters', queryString);
            const sort = getQueryStringValue('sort', queryString);

            if (filters && filters.length) {
                dispatch(setFilters(filters));
            } else {
                dispatch(clearFilters(''));
            }
            if (sort) {
                setSort(sort);
            } else {
                setSort('a_z');
            }
            setPage(0);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    const startLoading = useRef(false);

    useEffect(() => {
        if (charityLoading) {
            startLoading.current = true;
        }
        if (
            startLoading.current &&
            !charityLoading &&
            charityFromApi?.data !== undefined &&
            JSON.stringify(charityFromApi) !== JSON.stringify(charityCache)
        ) {
            setCharityCache(charityFromApi);
            startLoading.current = false;
        }
    }, [charityLoading]);

    const isFirstLoad = useRef(true);

    useEffect(() => {
        if (isFirstLoad.current) {
            filters.map(type => {
                if (router.query[type.type] !== undefined) {
                    const queryFilters = router.query[type.type].split(',');
                    queryFilters.map(queryFilter => {
                        const hasFilter = type.data.filter(f => f.value === queryFilter);
                        if (hasFilter.length > 0) {
                            const obj = { type: type.type, value: hasFilter[0].value, label: hasFilter[0].label };
                            dispatch(addFilter(obj));
                        }
                        return false;
                    });
                }
                return false;
            });
            isFirstLoad.current = false;
            return;
        }
        return () => {
            // clearAllBodyScrollLocks();
        };
    });

    useEffect(() => {
        if (filterOpen) {
            setBodyNoScroll(true);
        } else {
            setBodyNoScroll(false);
        }
        return () => {
            setBodyNoScroll(false);
        };
    }, [filterOpen]);

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');

    useEffect(() => {
        if (isDesktop) {
            setFilterOpen(false);
        }
    }, [isDesktop]);

    const RenderSelectedFilters = () => (
        <>
            {selectedFilters.map((filter, i) => (
                <button
                    key={i}
                    type='button'
                    data-type={filter.type}
                    data-label={filter.label}
                    data-value={filter.value}
                    className={style.filterButton}
                    onClick={removeThisFilter}
                >
                    {filter.label}
                    <span className={style.cross} />
                </button>
            ))}
        </>
    );

    const handleSortChange = value => {
        const val = typeof value === 'string' ? value : value.target.value;
        if (val !== sort) {
            setSort(val);
        }
    };

    const isFirstSort = useRef(true);

    useEffect(() => {
        if (isFirstSort.current) {
            isFirstSort.current = false;
            return;
        }
        dispatch(fetchCharity(page, convertFiltersToString(), sort));
        if (sort === 'a_z') {
            removeQueryStringValue('sort', '', router);
        } else {
            setQueryStringValue('sort', sort, '', router);
        }
    }, [sort]);

    useEffect(() => {
        dispatch(fetchCharity(page, convertFiltersToString(), sort, true));
    }, [page]);

    return (
        <div className={style.el} ref={elRef}>
            <div className={[style.container, 'container'].join(' ')}>
                <h1 className={style.headline}>{title}</h1>
                <div className={style.filters}>
                    <div className={style.filtersMobile}>
                        {selectedFilters?.length > 0 ? (
                            <button type='button' className={style.clearButton} onClick={clearFiltersClick}>
                                <span className='icon-close' />
                                {copies?.clear}
                            </button>
                        ) : (
                            <div />
                        )}
                        <button type='button' className={style.filterButton} onClick={filterClick}>
                            <span className='icon-filter' />
                            {copies?.filter}
                        </button>
                    </div>
                    <div
                        className={
                            [style.filtersWrapper, filterOpen ? style.open : ''].join(' ') +
                            prepareGlobalFilterClasses(filters.length + 1, filters.length + 1, true)
                        }
                        ref={filtersRef}
                    >
                        <div className={style.filterHeadMobile}>
                            <button className={style.backBtn} onClick={() => setFilterOpen(false)}>
                                {/* <span className='icon-arrow-right' /> */}
                                <span className='sr-only'>back</span>
                            </button>
                            <button className={style.closeBtn} onClick={() => setFilterOpen(false)}>
                                <span className='icon-close' />
                                <span className='sr-only'>close</span>
                            </button>
                        </div>
                        {selectedFilters?.length > 0 && (
                            <div className={[style.selectedFilters, 'mobile'].join(' ')}>
                                <RenderSelectedFilters />
                            </div>
                        )}
                        <div className={style.filterSelectWrap}>
                            <input
                                value={searchFilter.value}
                                onChange={handleSearchChanged}
                                className={style.searchBox}
                                type='text'
                                name='keywords'
                                placeholder={copies?.keywordsSearch}
                            />
                        </div>
                        {filters?.map(item => (
                            <div className={style.filterSelectWrap} key={item.type}>
                                <FilterSelect
                                    {...item}
                                    handleSelect={handleFilterSelect}
                                    selectedFilters={Array.from(selectedFilters).filter(f => f.type === item.type)}
                                />
                            </div>
                        ))}
                        <div className={style.filterBottomMobile}>
                            <button className={style.applyBtn} onClick={() => setFilterOpen(false)}>
                                {copies?.apply}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={style.filtersSort + prepareGlobalFilterClasses(filters.length + 1, 2)}>
                    <div className={[style.selectedFilters, 'desktop'].join(' ')}>
                        {selectedFilters.length > 0 && <RenderSelectedFilters />}
                    </div>
                    <div className={[style.numberTeamMembersMobile, 'mobile'].join(' ')}>
                        {copies?.charityCountLabel && (
                            <>
                                <span className={style.number}>{charityCache.totalItems}</span>{' '}
                                {copies?.charityCountLabel}
                            </>
                        )}
                    </div>
                    <div className={style.sortSelect}>
                        <CustomSelect
                            active={sort}
                            data={sortSelect}
                            defaultTitle={copies?.sortBy}
                            colourVariant={'orange'}
                            handleSelect={handleSortChange}
                        />
                    </div>
                </div>
                <div className={[style.numberTeamMembers, 'desktop'].join(' ')}>
                    <div>
                        {copies?.charityCountLabel && (
                            <>
                                <span className={style.number}>{charityCache.totalItems}</span>{' '}
                                {copies?.charityCountLabel}
                            </>
                        )}
                    </div>
                    {selectedFilters.length > 0 && (
                        <button
                            type='button'
                            className={[style.clearButton, 'desktop'].join(' ')}
                            onClick={clearFiltersClick}
                        >
                            <span className='icon-close' />
                            {copies?.clearFilters}
                        </button>
                    )}
                </div>
                <div className={style.newsWrapper}>
                    <InfiniteScroll
                        dataLength={charityCache.data.length}
                        next={fetchData}
                        hasMore={charityCache.currentPage < charityCache.totalPages}
                        loader={<div className={style.loading}><LottieAnimation json={loadingLottieJSON} loop={true} playImmediately={true} /></div>}
                        endMessage={<div></div>}
                    >
                        {charityCache.data.length > 0 ? (
                            <CharityList charities={charityCache} />
                        ) : (
                            <div className={style.noResults}>{copies?.noResultMessage}</div>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
};

CharityListing.propTypes = {
    title: PropTypes.string,
    filters: PropTypes.array,
    charities: PropTypes.object,
    copies: PropTypes.object,
};

export default CharityListing;
