import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PortfolioCard from '../Cards/portfolioCard';
import style from './PortfolioList.module.scss';
import ReactModal from 'react-modal';
import ModalPageContent from '../ModalPageContent';
const PortfolioList = props => {
    const { portfolios } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [routePath, setRoutePath] = useState(true);
    // const filterNames = filters?.map(type =>
    //     type.value.map(value => [type.type, value.value].join('-')).join(' ')
    //   )
    //   .join(' ');

    const handleOpenPortfolio = (routePath) => {
        setRoutePath(routePath);
        if (routePath) {
            setIsOpen(true);
        }
    }
    return (
        <>
            <div className={style.content}>
                <div className={style.items}>
                    {portfolios.data.map((item, id) => (
                        <div className={style.card} key={id}>
                            <PortfolioCard {...item} handleOpen={handleOpenPortfolio} />
                        </div>
                    ))}
                </div>
            </div>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                    setRoutePath(null);
                }}
                className='page-modal portfolio-modal'
                overlayClassName='page-modal-overlay'
                contentLabel='Modal'
                closeTimeoutMS={800}
            >
                <button type='button' className='close-btn' onClick={() => {
                    setIsOpen(false);
                    setRoutePath(null);
                }}>
                    <span />
                    <span />
                </button>
                <ModalPageContent key={routePath} pagePath={routePath} />
            </ReactModal>
        </>
    );
};

PortfolioList.propTypes = {
    portfolios: PropTypes.object,
};

export default PortfolioList;
