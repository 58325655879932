import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import style from './MapGlobalContent.module.scss';
import { GoogleMap, Marker, InfoWindow, useLoadScript, MarkerClusterer } from '@react-google-maps/api';

const MapGlobalContent = (props) => {
    const {
        googleMapKey,
        heading,
        mainDescription,
        centerLatitude,
        centerLongitude,
        defaultZoom,
        regions,
        globalSelectButtonText,
        contentTiles,
    } = props;

    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: googleMapKey,
    });

    const defaultCenter = {
        lat: centerLatitude,
        lng: centerLongitude,
    };

    const titleList = [globalSelectButtonText, ...regions.map((item) => item.title)];

    const [selectedTab, setSelectedTab] = useState(globalSelectButtonText);
    const [contentData, setContentData] = useState(contentTiles);
    const [selectedTileItem, setSelectedTileItem] = useState(null);
    const [center, setCenter] = useState(defaultCenter);
    const [zoom, setZoom] = useState(defaultZoom);
    const [startIdx, setStartIdx] = useState(0);

    const resetMapToDefault = () => {
        setZoom(defaultZoom);
        setCenter(defaultCenter);
    };

    const updateMap = (zoom, lat, lng) => {
        setZoom(zoom);
        setCenter({ lat, lng });
    };

    useEffect(() => {
        if (selectedTab === globalSelectButtonText) {
            setContentData(contentTiles);
            resetMapToDefault();
        } else {
            const region = regions.find((item) => item.title === selectedTab);
            setContentData(region.contentTiles);
            updateMap(region.defaultZoom, region.centerLatitude, region.centerLongitude);
        }
        setStartIdx(0);
        setSelectedTileItem(null);
    }, [selectedTab]);

    useEffect(() => {
        if (selectedTileItem !== null) {
            if (selectedTileItem) {
                updateMap(selectedTileItem.defaultZoom, selectedTileItem.latitude, selectedTileItem.longitude);
            } else {
                resetMapToDefault();
            }
        }
    }, [selectedTileItem]);
    return (
        <div className={[style.el, 'global-map'].join(' ')}>
            <section className={style.section}>
                <div className={'container'}>
                    <div className={style.title}>
                        <h2>{heading}</h2>
                    </div>
                    <div className={style.description} dangerouslySetInnerHTML={{ __html: mainDescription }} />
                    <div className={style.container}>
                        <div className={style.map}>
                            {isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: '100%',
                                        minHeight: 500,
                                    }}
                                    options={{
                                        disableDefaultUI: true,
                                    }}
                                    center={center}
                                    zoom={zoom}
                                >
                                    <MarkerClusterer
                                        minimumClusterSize={3}
                                        styles={[
                                            {
                                                url: '/images/map/marker.svg',
                                                height: 40,
                                                width: 40,
                                                textColor: 'white',
                                                textSize: 12,
                                                anchorText: [-2.5, -3],
                                            },
                                        ]}
                                    >
                                        {(clusterer) => (
                                            <>
                                                {contentData.map((item) => (
                                                    <Marker
                                                        key={item.id}
                                                        clusterer={clusterer}
                                                        style={{
                                                            borderRadius: 999,
                                                            overflow: 'hidden',
                                                        }}
                                                        icon={{
                                                            url: '/images/map/marker-2.svg',
                                                        }}
                                                        position={{ lat: item.latitude, lng: item.longitude }}
                                                    >
                                                        {selectedTileItem?.id === item.id && (
                                                            <InfoWindow className={style['info-box']}>
                                                                <img
                                                                    className={style['info-box__logo']}
                                                                    src={item.logo.url}
                                                                    alt={item.logo.alt}
                                                                />
                                                            </InfoWindow>
                                                        )}
                                                    </Marker>
                                                ))}
                                            </>
                                        )}
                                    </MarkerClusterer>
                                </GoogleMap>
                            )}
                        </div>
                        <TabList tabTitleList={titleList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        <TileContentList
                            contentData={contentData}
                            selectedTileItem={selectedTileItem}
                            setSelectedTileItem={setSelectedTileItem}
                            startIdx={startIdx}
                            setStartIdx={setStartIdx}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

const TileContentList = ({ contentData, setSelectedTileItem, selectedTileItem, startIdx, setStartIdx }) => {
    const max = 3;

    const isHasNext = useMemo(() => startIdx + max < contentData.length, [startIdx, contentData.length]);
    const isHasPrev = useMemo(() => startIdx > 0, [startIdx]);

    const handleNext = () => isHasNext && setStartIdx((prev) => prev + max);
    const handlePrev = () => isHasPrev && setStartIdx((prev) => prev - max);

    const visibleContent = useMemo(() => {
        const slicedContent = contentData.slice(startIdx, startIdx + max);

        while (slicedContent.length < max) {
            slicedContent.push({});
        }

        return slicedContent;
    }, [startIdx, contentData]);

    return (
        <div className={style['tile-content-list']}>
            {visibleContent.map((data, index) =>
                data.id ? (
                    <TileContentItem
                        {...data}
                        key={data.id}
                        setSelectedTileItem={setSelectedTileItem}
                        isActive={data.id === selectedTileItem?.id}
                    />
                ) : (
                    <div className={style['tile-item-placeholder']} key={`placeholder-${index}`} />
                )
            )}

            <div className={style.pagination}>
                <button
                    className={[style['button-prev'], !isHasPrev && style.disable].join(' ')}
                    onClick={handlePrev}
                    aria-label="Previous"
                >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                            width="11.1412"
                            height="11.1412"
                            transform="matrix(-1 0 0 1 11.5825 0.0537109)"
                            fill="white"
                        />
                        <path
                            d="M9.72559 5.62452L2.29812 5.62452M2.29812 5.62452L5.64048 2.375M2.29812 5.62452L5.64048 8.87404"
                            stroke="#E67C25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
                <button
                    className={[style['button-next'], !isHasNext && style.disable].join(' ')}
                    onClick={handleNext}
                    aria-label="Next"
                >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                            width="11.1412"
                            height="11.1412"
                            transform="translate(0.0585938 0.0537109)"
                            fill="white"
                        />
                        <path
                            d="M1.91553 5.62452L9.343 5.62452M9.343 5.62452L6.00064 2.375M9.343 5.62452L6.00064 8.87404"
                            stroke="#E67C25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

const TileContentItem = (props) => {
    const { title, description, logo, link, setSelectedTileItem, isActive, sector, location, region, year } = props;
    return (
        <div
            className={[style['tile-item'], isActive && style.active].join(' ')}
            onClick={() => setSelectedTileItem(props)}
        >
            <div className={style['tile-item-left']}>
                {logo?.url && <img src={logo.url} alt={logo?.alt} className={style['tile-item-left__logo']} />}
                <div>
                    {sector && (
                        <p>
                            <strong>{sector}</strong>
                        </p>
                    )}
                    {location && region && (
                        <p>
                            {region} - {location}
                        </p>
                    )}
                    {year && <p>Partner Since {year}</p>}
                </div>
            </div>
            <div className={style['tile-item-right']}>
                <h5 className={style['tile-item-right__title']}>{title}</h5>
                <p className={style['tile-item-right__description']}>{description}</p>
                {link?.link?.url && (
                    <div className={style['tile-item-right__read-more']}>
                        <a href={link.link.url}>{link.text}</a>
                    </div>
                )}
            </div>
        </div>
    );
};

const TabList = ({ tabTitleList, selectedTab, setSelectedTab }) => {
    return (
        <div className={style['tab-list']}>
            {tabTitleList.map((title) => (
                <Tab title={title} isActive={title === selectedTab} setSelectedTab={setSelectedTab} key={title} />
            ))}
        </div>
    );
};

const Tab = ({ title, isActive, setSelectedTab }) => {
    return (
        <div className={[style.tab, isActive && style.active].join(' ')} onClick={() => setSelectedTab(title)}>
            <p>{title}</p>
        </div>
    );
};

TileContentList.propTypes = {
    contentData: PropTypes.array,
    setSelectedTileItem: PropTypes.func,
    selectedTileItem: PropTypes.object,
    startIdx: PropTypes.number,
    setStartIdx: PropTypes.func,
};

TileContentItem.propTypes = {
    title: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    defaultZoom: PropTypes.number,
    description: PropTypes.string,
    logo: PropTypes.object,
    logoSubText: PropTypes.string,
    link: PropTypes.object,
    setSelectedTileItem: PropTypes.func,
    isActive: PropTypes.object,
    sector: PropTypes.string,
    location: PropTypes.string,
    region: PropTypes.string,
    year: PropTypes.string,
};

TabList.propTypes = {
    tabTitleList: PropTypes.arrayOf(string),
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func,
};

Tab.propTypes = {
    title: PropTypes.string,
    isActive: PropTypes.bool,
    setSelectedTab: PropTypes.func,
};

MapGlobalContent.propTypes = {
    heading: PropTypes.string,
    mainDescription: PropTypes.string,
    centerLatitude: PropTypes.number,
    centerLongitude: PropTypes.number,
    defaultZoom: PropTypes.number,
    globalSelectButtonText: PropTypes.string,
    contentTiles: PropTypes.array,
    regions: PropTypes.array,
    googleMapKey: PropTypes.string,
};

export default MapGlobalContent;
