import React from 'react';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@react-hook/media-query';

import style from './PortfolioCard.module.scss';

const PortfolioCard = ({ name, description, icon, cta, handleOpen }) => {
    // const isMobile = useMediaQuery('only screen and (max-width: 599px)');
    // const url = cta?.link.url ?? 'javascript:void(0)';
    return (
        <div onClick={() => handleOpen(cta.link.url, cta.link.target === '' ? '_self' : cta.link.target)} className={style.el}>
            <div className={style.imageWrap}>
                <div className={style.image} style={{ backgroundImage: `url(${icon?.url})` }} />
            </div>
            <div className={style.overlay}>
                <div className={style.overlayWrap}>
                    <div>
                        <div className={style.companyName}>
                            <h3>{name}</h3>
                        </div>
                        <div className={style.companyDesc}>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div onClick={() => handleOpen(cta.link.url, cta.link.target === '' ? '_self' : cta.link.target)}>
                        <p className={[style.cta, 'arrow-link'].join(' ')}>
                            {cta?.text}
                            {' '}
                            <span className='icon-arrow-right' />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

PortfolioCard.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.object,
    cta: PropTypes.object,
    handleOpen: PropTypes.func
};

export default PortfolioCard;
