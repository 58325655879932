import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import style from './GridCards.module.scss';
import PortfolioCard from '../Cards/portfolioCard';
const GridCards = props => {
    const { cards } = props;
    const elRef = useRef(null);
    // const filterNames = filters?.map(type =>
    //     type.value.map(value => [type.type, value.value].join('-')).join(' ')
    //   )
    //   .join(' ');
    const handleOpenLink = (routePath, target) => {
        window.open(routePath, target);
    }
    return (
        <>
            <div className={style.el} ref={elRef}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.content}>
                        <div className={style.items}>
                            {cards.map((item, id) => (
                                <div className={style.card} key={id}>
                                    <PortfolioCard name={item.title} description={item.description} icon={item.icon} cta={item.cta} handleOpen={handleOpenLink} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

GridCards.propTypes = {
    cards: PropTypes.object,
};

// name: PropTypes.string,
// description: PropTypes.string,
// icon: PropTypes.object,
// cta: PropTypes.objec
export default GridCards;
